<template>

  <div class="card bg-warning text-white">
    <div class="card-header bg-warning text-dark align-items-center d-flex">
      <h5 class="card-title text-dark  mb-0 me-2">
        Saldo
      </h5>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-sm-12">
          <b-tabs content-class="mt-3"
                  titleItemClass="aba_usuario_perfil"
                  class="nav-pills animation-nav gap-2 gap-lg-3 flex-grow-1">
            <b-tab title="Saldo atual"
                   titleItemClass="aba_usuario_perfil"
                   active
                    >
              <h3>
                <LoadingText v-if="this.UsuarioSaldo === 0"/>
                <count-to v-else
                          :duration="3000"
                          :startVal='0'
                          :decimals='2'
                          :options="{separator: '.', decimal: ',', decimalPlaces: 2}"
                          :endVal='this.UsuarioSaldo'
                          prefix="R$ " ></count-to>


              </h3>
            </b-tab>
            <b-tab title="Total em vendas"
                   titleItemClass="aba_usuario_perfil">
              <h3>
                <count-to :duration="3000"
                          :startVal='0'
                          :decimals='2'
                          :options="{separator: '.', decimal: ',', decimalPlaces: 2}"
                          :endVal='this.UsuarioSaldo'
                          prefix="R$ " ></count-to>
              </h3>
            </b-tab>
            <b-tab title="À receber" titleItemClass="aba_usuario_perfil">
              <h3>
                <count-to :duration="3000"
                          :startVal='0'
                          :decimals='2'
                          :options="{separator: '.', decimal: ',', decimalPlaces: 2}"
                          :endVal='this.UsuarioSaldo'
                          prefix="R$ "  ></count-to>
              </h3>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>
<style scoped>
.aba_usuario_perfil {
  color: #0041ff !important;
}
.aba_usuario_perfil .active {
  color: #f5f5f5 !important;
}
.profile-nav.nav-pills .nav-link {
  color: #0041ff !important;


}
</style>
<script itemscope>
import axios from "axios";
import moment from "moment/moment";
import {  CountTo } from "vue3-count-to";
import LoadingText from "@/components/loading/LoadingText.vue";


export default {
  components:{
    LoadingText,
    CountTo
  },
  props: [
    'usuario'
  ],
  data () {
    return {
      UsuarioSaldo:0,
      UsuarioTotal:0,
      UsuarioReceber:0,
      Usuario:this.usuario
    }
  },
  methods: {
    async ConsultaSaldoUsuario() {
      try {
        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard/usuariosaldo/'+this.Usuario);

        result.data.forEach(item => {
          // Setando os labels
          //this.chartData.labels.push(this.formatarMes(item.mes));
          // Setando os dados
          //var valor = item.valorNfeTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
          //this.chartData.datasets[0].data.push(valor);

          this.UsuarioSaldo = item.somaNfe;
          this.UsuarioTotal = (item.somaNfe * 2);
          this.UsuarioReceber = (item.somaNfe / 2);
        });



      } catch (e) {
        console.log(e);
      }
    },
    formatarData (data) {
      return  moment(data).format('DD/MM/YYYY HH:mm:ss');
    },

  },
  mounted() {
    this.ConsultaSaldoUsuario();
  }
};
</script>
