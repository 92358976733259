<template>

  <div class="card">
    <div class="card-header align-items-center d-flex">
      <h5 class="card-title mb-0 me-2">
        Resumo NF-e 6 meses
      </h5>
    </div>
    <div class="card-body">
      <div class="chart-wrapper">

        <canvas id="myChart" width="400" height="400"></canvas>

      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>
<style>
.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

canvas {
  padding: 0.5rem;
}
</style>
<script itemscope>
import axios from "axios";
import moment from "moment/moment";
import Chart from 'chart.js';

export default {
  components: {},
  props: [
    'usuario'
  ],
  data() {
    return {
      chartData: {
        labels: [],
        valorTotal: [],
        quantidadeTotal: [],
        bgColors: [],
        bgBorder: [],
      },
      Usuario: this.usuario
    }
  },
  setup() {
    return {};
  },
  methods: {
    async ConsultaUsuarioGraficoNfse() {
      try {
        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard/usuariograficonfse/' + this.Usuario)
            .then(response => {
              this.labels = response.data.map(item => {
                return item.mes;
              });
              this.valorTotal = response.data.map(item => {
                return item.valorNfeTotal;
              });
              this.quantidadeTotal = response.data.map(item => {
                return item.quantidadeNfeTotal;
              })
            })
            .catch(function (error) {
              console.log(error);
            });
        console.log(result);


        this.criarGrafico();

      } catch (e) {
        console.log(e);
      }
    },
    formatarData(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss');
    },
    criarGrafico() {
      let ctx = document.getElementById('myChart').getContext('2d');
      let myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [{
            label: 'Valor Total',
            data: this.valorTotal,
            backgroundColor: 'rgba(3, 255, 0, 0.8)',
            borderWidth: 1,
          },
            {
              label: 'Quantidade Total',
              data: this.quantidadeTotal,
              backgroundColor: 'rgba(0, 79, 235, 0.9)',
              borderWidth: 1
            }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
      console.log(myChart);
    },
    formatarMes(mes) {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];
      return meses[mes - 1];
    },
  },
  mounted() {
    this.ConsultaUsuarioGraficoNfse();
  }
};
</script>
