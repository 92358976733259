<template>

  <div class="card">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 me-2">
        Atividades  recentes
      </h4>
      <div class="flex-shrink-0 ms-auto">
        <ul
            class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
            role="tablist"
        >
          <li class="nav-item">
            <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#today"
                role="tab"
            > Notificações </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content text-muted">
        <div class="tab-pane active" id="today" role="tabpanel" >
          <div class="profile-timeline">
            <div class="accordion accordion-flush" id="todayExample">
              <div v-for="(item, index) of Pagelista" :key="index"
                   class="accordion-item border-0">
                  <div class="accordion-header" id="heading">
                  <a class="accordion-button p-2 shadow-none"
                      data-bs-toggle="collapse"
                      :href="`#collapseOne${index}`"
                      aria-expanded="true" >
                    <div class="d-flex">
                      <div class="flex-shrink-0">
                        <img
                            :src="`${item.usuarios.Photo}`"
                            alt=""
                            class="avatar-xs rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <h6 class="fs-14 mb-1">
                          {{item.usuarios.Nome}}
                        </h6>
                        <small class="text-muted" >
                          {{item.push_content}}
                          em
                          {{formatarData(item.datahora) }}
                        </small
                        >
                      </div>
                    </div>
                  </a>
                </div>
                <div
                    :id="`collapseOne${index}`"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body ms-2 ps-5">
                    {{item.push_content}}
                  </div>
                </div>
              </div>
            </div>
            <!--end accordion-->
          </div>
        </div>
      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>

<script itemscope>
import axios from "axios";
import moment from "moment/moment";
export default {
  components:{
  },
  props: [
    'lista',
    'usuario'
  ],
  data() {
    return {
      valorSelecionado: '',
      Pagelista : {},
      Usuario : this.usuario,
    }

  },
  setup() {
    return {
      dealsStatus: [
        {
          id: 1,
          name: "Absternet LLC",
          date: "Sep 20, 2021",
          img: require("@/assets/images/users/avatar-1.jpg"),
          representativeName: "Donald Risher",
          badgeClass: "success",
          status: "Deal Won",
          statusValue: "$100.1K",
        },
        {
          id: 2,
          name: "Raitech Soft",
          date: "Sep 23, 2021",
          img: require("@/assets/images/users/avatar-2.jpg"),
          representativeName: "Sofia Cunha",
          badgeClass: "warning",
          status: "Intro Call",
          statusValue: "$150K",
        },
        {
          id: 3,
          name: "William PVT",
          date: "Sep 27, 2021",
          img: require("@/assets/images/users/avatar-3.jpg"),
          representativeName: "Luis Rocha",
          badgeClass: "danger",
          status: "Stuck",
          statusValue: "$78.18K",
        },
        {
          id: 4,
          name: "Loiusee LLP",
          date: "Sep 30, 2021",
          img: require("@/assets/images/users/avatar-4.jpg"),
          representativeName: "Vitoria Rodrigues",
          badgeClass: "success",
          status: "Deal Won",
          statusValue: "$180K",
        },
        {
          id: 5,
          name: "Apple Inc.",
          date: "Sep 30, 2021",
          img: require("@/assets/images/users/avatar-6.jpg"),
          representativeName: "Vitoria Rodrigues",
          badgeClass: "info",
          status: "New Lead",
          statusValue: "$78.9K",
        },
      ],
    };
  },
  methods: {
    async ConsultaNotificacaoUsuario() {

      try {
        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard/usuarionotificacao/'+this.Usuario);
        this.Pagelista = result.data.data;

      } catch (e) {
        console.log(e);
      }
    },
    formatarData (data) {
      return  moment(data).format('DD/MM/YYYY HH:mm:ss');
    }
  },
  mounted() {
    this.ConsultaNotificacaoUsuario();
  }
};
</script>
