<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img
            src="@/assets/images/profile-bg.jpg"
            alt=""
            class="profile-wid-img"
        />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img
                :src="usuario.Photo"
                alt="user-img"
                class="img-thumbnail rounded-circle"
            />
          </div>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{ usuario.Nome }}</h3>
            <p class="text-white-75">
              {{ usuario.CPF }}
              <br>
                                     {{ usuario.Descricao }}
            </p>
            <div class="hstack text-white-50 gap-1">
              <div class="me-2">
                <i
                    class="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"
                ></i
                >{{ usuario.UF }}
              </div>
              <div>
                <i
                    class="ri-building-line me-1 text-white-75 fs-16 align-middle"
                ></i
                >{{ usuario.NomeProfissional }}
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="col-12 col-lg-auto order-last order-lg-0">
          <div class="row text text-white-50 text-center">
            <div class="col-lg-6 col-4">
              <div class="p-2">

              </div>
            </div>
            <div class="col-lg-6 col-4">
              <div class="p-2">

              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">

            <b-tabs content-class="mt-3" class="nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1">
              <b-tab title="Cadastro" active>
                <div class="row">
                  <div class="col-xxl-3">

                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title mb-3">
                          <div class="row">
                            <div class="col-sm-12">

                              <b-button class="btn btn-warning btn-sm d-inline-block me-2"
                                        @click="enviarEmail">
                                Email
                              </b-button>

                              <b-button class="btn btn-success btn-sm d-inline-block me-2"
                                        @click="LinkContabilidade">
                                Contabilidade
                              </b-button>

                              <b-button class="btn btn-info btn-sm d-inline-block">
                                Enviar mensagem
                              </b-button>

                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6">

                            </div>
                            <div class="col-sm-6">

                            </div>
                          </div>

                        </h5>
                        <div class="table-responsive">
                          <table class="table table-borderless mb-0">
                            <tbody>
                            <tr>
                              <th class="ps-0" scope="row">Perfil :</th>
                              <td class="text-muted">{{ usuario.Descricao }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Nome :</th>
                              <td class="text-muted">{{ usuario.Nome }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Nome profissional:</th>
                              <td class="text-muted">{{ usuario.NomeProfissional }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">CPF :</th>
                              <td class="text-muted">{{ usuario.CPF }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">CNPJ :</th>
                              <td class="text-muted">{{ usuario.CNPJ }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Telefone :</th>
                              <td class="text-muted">{{ usuario.Celular }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">E-mail :</th>
                              <td class="text-muted">{{ usuario.email }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Data de nascimento</th>
                              <td class="text-muted">{{ getHumanDate(usuario.DataNascimento) }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!--end col-->
                  <div class="col-xxl-9">

                    <div class="row">
                      <div class="col-lg-4">
                        <UsuarioResumoSaldoConta :usuario="usuario.Id"/>
                        <UsuarioGraficoNfse :usuario="usuario.Id"/>
                      </div>
                      <div class="col-lg-8">
                        <UsuarioNotificacoes :usuario="usuario.Id"/>
                        <!-- end card -->
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row -->

                  </div>
                  <!--end col-->
                </div>
              </b-tab>
              <b-tab title="Procedimentos" @click="switchTab('Procedimento')" >
                <UsuarioProcedimentos :usuario="usuario.Id"
                                      activeTab="tab1"
                                      :changeTab="activeTab"
                                      ref="UsuarioProcedimento"
                                       />
              </b-tab>
              <b-tab title="Eventos" @click="switchTab('Eventos')">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title mb-3">Atendimentos</h5>
                    <div class="acitivity-timeline">
                      <div class="acitivity-item d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-1.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Oliver Phillips <span
                              class="badge bg-soft-primary text-primary align-middle"
                          >New</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            We talked about a project on linkedin.
                          </p>
                          <small class="mb-0 text-muted">Today</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                          <div
                              class="avatar-title bg-soft-success text-success rounded-circle"
                          >
                            N
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Nancy Martino <span
                              class="badge bg-soft-secondary text-secondary align-middle"
                          >In Progress</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            <i class="ri-file-text-line align-middle ms-2"></i> Create new project Buildng product
                          </p>
                          <div class="avatar-group mb-2">
                            <a
                                href="javascript: void(0);"
                                class="avatar-group-item"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Christi"
                            > <img
                                src="@/assets/images/users/avatar-4.jpg"
                                alt=""
                                class="rounded-circle avatar-xs"
                            /> </a> <a
                              href="javascript: void(0);"
                              class="avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Frank Hook"
                          > <img
                              src="@/assets/images/users/avatar-3.jpg"
                              alt=""
                              class="rounded-circle avatar-xs"
                          /> </a> <a
                              href="javascript: void(0);"
                              class="avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title=" Ruby"
                          >
                            <div class="avatar-xs">
                              <div
                                  class="avatar-title rounded-circle bg-light text-primary"
                              >
                                R
                              </div>
                            </div>
                          </a> <a
                              href="javascript: void(0);"
                              class="avatar-group-item"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="more"
                          >
                            <div class="avatar-xs">
                              <div class="avatar-title rounded-circle">2+</div>
                            </div>
                          </a>
                          </div>
                          <small class="mb-0 text-muted">Yesterday</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-2.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Natasha Carey <span class="badge bg-soft-success text-success align-middle" >Completed</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            Adding a new event with attachments
                          </p>
                          <div class="row">
                            <div class="col-xxl-4">
                              <div class="row border border-dashed gx-2 p-2 mb-2">
                                <div class="col-4">
                                  <img
                                      src="@/assets/images/small/img-2.jpg"
                                      alt=""
                                      class="img-fluid rounded"
                                  />
                                </div>
                                <!--end col-->
                                <div class="col-4">
                                  <img
                                      src="@/assets/images/small/img-3.jpg"
                                      alt=""
                                      class="img-fluid rounded"
                                  />
                                </div>
                                <!--end col-->
                                <div class="col-4">
                                  <img
                                      src="@/assets/images/small/img-4.jpg"
                                      alt=""
                                      class="img-fluid rounded"
                                  />
                                </div>
                                <!--end col-->
                              </div>
                              <!--end row-->
                            </div>
                          </div>
                          <small class="mb-0 text-muted">25 Nov</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-6.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">Bethany Johnson</h6>
                          <p class="text-muted mb-2">
                            added a new member to velzon dashboard
                          </p>
                          <small class="mb-0 text-muted">19 Nov</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <div class="avatar-xs acitivity-avatar">
                            <div
                                class="avatar-title rounded-circle bg-soft-danger text-danger"
                            >
                              <i class="ri-shopping-bag-line"></i>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            Your order is placed <span
                              class="badge bg-soft-danger text-danger align-middle ms-1"
                          >Out of Delivery</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            These customers can rest assured their order has been placed.
                          </p>
                          <small class="mb-0 text-muted">16 Nov</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-7.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">Lewis Pratt</h6>
                          <p class="text-muted mb-2">
                            They all have something to say beyond the words on the page. They can come across as casual
                            or neutral, exotic or graphic.
                          </p>
                          <small class="mb-0 text-muted">22 Oct</small>
                        </div>
                      </div>
                      <div class="acitivity-item py-3 d-flex">
                        <div class="flex-shrink-0">
                          <div class="avatar-xs acitivity-avatar">
                            <div
                                class="avatar-title rounded-circle bg-soft-info text-info"
                            >
                              <i class="ri-line-chart-line"></i>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">Monthly sales report</h6>
                          <p class="text-muted mb-2">
                            <span class="text-danger">2 days left</span> notification to submit the monthly sales
                                                                         report. <a
                              href="javascript:void(0);"
                              class="link-warning text-decoration-underline"
                          >Reports Builder</a
                          >
                          </p>
                          <small class="mb-0 text-muted">15 Oct</small>
                        </div>
                      </div>
                      <div class="acitivity-item d-flex">
                        <div class="flex-shrink-0">
                          <img
                              src="@/assets/images/users/avatar-8.jpg"
                              alt=""
                              class="avatar-xs rounded-circle acitivity-avatar"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h6 class="mb-1">
                            New ticket received <span
                              class="badge bg-soft-success text-success align-middle"
                          >Completed</span
                          >
                          </h6>
                          <p class="text-muted mb-2">
                            User <span class="text-secondary">Erica245</span> submitted a ticket.
                          </p>
                          <small class="mb-0 text-muted">26 Aug</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end card-body-->
                </div>
              </b-tab>
              <b-tab title="Notas fiscais">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Orçamentos">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Equipes">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Auditoria">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Dispositivos">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Pacientes">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Pagamentos">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Faturamento">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Hospital">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Cirurgião">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Situação de risco">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Serviço\Assinatura">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-4">
                      <h5 class="card-title flex-grow-1 mb-0">Documentos</h5>
                      <div class="flex-shrink-0">
                        <input
                            class="form-control d-none"
                            type="file"
                            id="formFile"
                        /> <label for="formFile" class="btn btn-danger"
                      ><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload arquivo</label
                      >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="table-responsive">
                          <table class="table table-borderless align-middle mb-0">
                            <thead class="table-light">
                            <tr>
                              <th scope="col">Nome do arquivo</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Tamanho</th>
                              <th scope="col">Data do upload</th>
                              <th scope="col">Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-primary text-primary rounded fs-20"
                                    >
                                      <i class="ri-file-zip-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0)"
                                      >Artboard-documents.zip</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Zip File</td>
                              <td>4.57 MB</td>
                              <td>12 Dec 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink15"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink15"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-file-pdf-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Bank Management System</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PDF File</td>
                              <td>8.89 MB</td>
                              <td>24 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink3"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink3"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-secondary text-secondary rounded fs-20"
                                    >
                                      <i class="ri-video-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Tour-video.mp4</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>MP4 File</td>
                              <td>14.62 MB</td>
                              <td>19 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink4"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink4"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-success text-success rounded fs-20"
                                    >
                                      <i class="ri-file-excel-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Account-statement.xsl</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>XSL File</td>
                              <td>2.38 KB</td>
                              <td>14 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink5"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink5"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle text-muted"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle text-muted"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li class="dropdown-divider"></li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle text-muted"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-info text-info rounded fs-20"
                                    >
                                      <i class="ri-folder-line"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Project Screenshots Collection</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>Floder File</td>
                              <td>87.24 MB</td>
                              <td>08 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink6"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink6"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="avatar-sm">
                                    <div
                                        class="avatar-title bg-soft-danger text-danger rounded fs-20"
                                    >
                                      <i class="ri-image-2-fill"></i>
                                    </div>
                                  </div>
                                  <div class="ms-3 flex-grow-1">
                                    <h6 class="fs-15 mb-0">
                                      <a href="javascript:void(0);"
                                      >Velzon-logo.png</a
                                      >
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG File</td>
                              <td>879 KB</td>
                              <td>02 Nov 2021</td>
                              <td>
                                <div class="dropdown">
                                  <a
                                      href="javascript:void(0);"
                                      class="btn btn-light btn-icon"
                                      id="dropdownMenuLink7"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                  > <i class="ri-equalizer-fill"></i> </a>
                                  <ul
                                      class="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuLink7"
                                  >
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-eye-fill me-2 align-middle"
                                      ></i
                                      >View</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-download-2-fill me-2 align-middle"
                                      ></i
                                      >Download</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                          class="dropdown-item"
                                          href="javascript:void(0);"
                                      ><i
                                          class="ri-delete-bin-5-line me-2 align-middle"
                                      ></i
                                      >Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-center mt-3">
                          <a href="javascript:void(0);" class="text-success"
                          ><i
                              class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                          ></i> Load more </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import axios from "axios";
import SwiperCore, {Thumbs, Navigation, Pagination} from "swiper";
import "swiper/swiper-bundle.css";
import moment from 'moment';
import UsuarioNotificacoes from "@/components/UsuarioNotificacoes.vue";
import UsuarioProcedimentos from "@/components/UsuarioProcedimentos.vue";
import UsuarioGraficoNfse from "@/components/UsuarioGraficoNfse.vue";
import UsuarioResumoSaldoConta from "@/components/UsuarioResumoSaldoConta.vue";

SwiperCore.use([Thumbs, Navigation, Pagination]);

export default {
  name: "Usuário",
  page: {
    title: "Usuário",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "Usuário",
      items: [
        {
          text: "usuario",
          href: "/",
        },
        {
          text: "Usuário",
          active: true,
        },
      ],
      URL: process.env.VUE_APP_API,
      activeTab: false,
      usuario: {
        "AliquotaPadrao": null,
        "Celular": null,
        "CNPJ": null,
        "CodigoRecuperacao": null,
        "CPF": null,
        "created_at": null,
        "CRM": null,
        "DataAberturaDeConta": null,
        "DataFechamentoDeConta": null,
        "DataHoraAcesso": null,
        "DataHoraRecuperacao": null,
        "DataNascimento": null,
        "HashLinkContabilidade": null,
        "email": null,
        "HashDeConfirmacao": null,
        "HashMercadoPago": null,
        "HashValidoAte": null,
        "Id": this.$route.params.id,
        "MostraAvisoCalculadora": null,
        "MPagoAlienUserId": null,
        "Nome": null,
        "NomeArquivoCarimboAssinatura": null,
        "NomeArquivoLogotipo": null,
        "NomeProfissional": null,
        "Photo": null,
        "ProcedimentosParticulares": null,
        "Sexo": null,
        "TipoUsuarioId": null,
        "UF": null,
        "updated_at": null,
        "usuario": null,
        "perfil_descricao": null,
        "verificado": 0
      }

    };
  },
  computed: {},
  mounted() {
    this.LoadUsuario();
  },
  watch: {

  },
  created() {

  },
  methods: {
    async LoadUsuario() {

      const result = await axios.get(process.env.VUE_APP_API + '/v1/usuario/' + this.$route.params.id, {
        Id: this.$route.params.id
      });
      console.log(result.data);

      this.usuario = result.data;

    },
    getHumanDate: function (date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    enviarEmail() {
      try {
        const consulta = axios.post(process.env.VUE_APP_API + '/v1/usuarioemail/');
        console.log(consulta.data);
      } catch (e) {
        console.log(e)
      }
    },
    LinkContabilidade() {
      try {

        let url = 'https://avpre.breathe.med.br/contabilidade?h='+this.usuario.HashLinkContabilidade;
        window.open(url, '_blank').focus();

      } catch (e) {
        console.log(e)
      }
    },
    switchTab(tab) {
      //this.activeTab = this.activeTab === 'home' ? 'about' : 'home';
      this.activeTab = tab === false ? false : tab;
    },
  },
  components: {
    UsuarioGraficoNfse,
    UsuarioProcedimentos,
    UsuarioNotificacoes,
    UsuarioResumoSaldoConta,
    Layout
  },
};
</script>