<template>

  <div class="card">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 me-2">
        Procedimentos
      </h4>

    </div>
    <div class="card-body">
      <div class="tab-content text-muted">
        <div class="tab-pane active" id="today" role="tabpanel">

          <div class="row">
            <div class="search-wrapper panel-heading col-sm-4 mb-5">
              <input class="form-control" type="text" v-model="search" placeholder="Procurar" />
              </div>
            </div>
          <div class="table-responsive">

            <LoadingDashTabelaProcedimentos v-if="!procedimentos" :speed="1" :width="1500" :height="400"></LoadingDashTabelaProcedimentos>

            <table class="table table-borderless align-middle mb-0" v-else>
              <thead>
              <tr>
                <th>ID</th>
                <th>Paciente</th>
                <th>Equipe</th>
                <th>Origem</th>
                <th>Orçamento</th>
                <th>Hospital</th>
                <th>Cirurgião</th>
                <th>Data Inicio</th>
                <th>Data Fim</th>
                <th>Nº Anes.</th>
                <th>Finalizado</th>
                <th>Nota Fiscal</th>
                <th>Data do cadastro</th>
              </tr>

              </thead>

              <tbody>

              <tr v-for="procedimento in procedimentos" :key="procedimento.Id">
                <td>{{ procedimento.Id }}</td>
                <td>{{ procedimento.paciente.Nome }}</td>
                <td>{{ procedimento.EquipeId }}</td>
                <td>{{ procedimento.Tipo }}</td>
                <td>0</td>
                <td v-if="procedimento.hospital">
                  <Popper  :placement="top" :disableClickAway="true" >
                    {{ procedimento.hospital.Nome }}
                    <template #content>
                      <div>
                        {{ procedimento.hospital.Nome }}
                        <hr>
                        <button class="btn btn-warning btn-sm d-inline-block me-2">
                          Abrir
                        </button>

                      </div>
                    </template>
                  </Popper>
                </td>
                <td v-else></td>
                <td v-if="procedimento.cirurgiao">{{ procedimento.cirurgiao.Nome }}</td>
                <td v-else></td>
                <td>{{formatarData(procedimento.DataInicio + ' '+procedimento.HoraInicio,'DD/MM/YYYY HH:mm:ss') }}</td>
                <td>{{formatarData(procedimento.DataFim + ' '+procedimento.HoraInicio,'DD/MM/YYYY HH:mm:ss') }}</td>

                <td>{{ procedimento.NumAnestesistas }}</td>
                <td>
                  <span v-if="procedimento.Finalizado === 1" class="badge bg-soft-success text-success align-middle" >Sim</span>
                  <span v-else class="badge bg-soft-danger text-danger align-middle" >Não</span>
                </td>
                <td>{{ procedimento.NumeroNFE }}</td>
                <td>{{formatarData(procedimento.created_at) }}</td>
              </tr>
              </tbody>

              <tfoot>
              <tr>
                <td colspan="10">
                  Página {{ page }} de {{ totalPages }}

                </td>
                <td class="text-end">
                  <button @click="getItensAnterior" class="btn btn-warning btn-sm d-inline-block me-2">Anterior</button>
                  <button @click="getItensProxima" class="btn btn-warning btn-sm d-inline-block me-2">Proxima</button>

                </td>
              </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>
<style scoped>
:deep(.popper) {
  background: #1d2226;
  padding: 16px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #1d2226;
}
</style>
<script>
import axios from "axios";
import moment from "moment/moment";
import LoadingDashTabelaProcedimentos from "@/components/loading/LoadingDashTabelaProcedimentos.vue";
import Popper from "vue3-popper";

export default {
  components: {
    LoadingDashTabelaProcedimentos,
    Popper
  },
  props: {
    lista: {
      type: Array,
    },
    usuario: {

    },
    changeTab: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: '',
      procedimentos: [],
      page: 1,
      totalPages: 1,
      canNext: true,
      Usuario: this.usuario
    }
  },
  setup() {
    return {
      dealsStatus: [
        {
          id: 1,
          name: "Absternet LLC",
          date: "Sep 20, 2021",
          img: require("@/assets/images/users/avatar-1.jpg"),
          representativeName: "Donald Risher",
          badgeClass: "success",
          status: "Deal Won",
          statusValue: "$100.1K",
        },
        {
          id: 2,
          name: "Raitech Soft",
          date: "Sep 23, 2021",
          img: require("@/assets/images/users/avatar-2.jpg"),
          representativeName: "Sofia Cunha",
          badgeClass: "warning",
          status: "Intro Call",
          statusValue: "$150K",
        },
        {
          id: 3,
          name: "William PVT",
          date: "Sep 27, 2021",
          img: require("@/assets/images/users/avatar-3.jpg"),
          representativeName: "Luis Rocha",
          badgeClass: "danger",
          status: "Stuck",
          statusValue: "$78.18K",
        },
        {
          id: 4,
          name: "Loiusee LLP",
          date: "Sep 30, 2021",
          img: require("@/assets/images/users/avatar-4.jpg"),
          representativeName: "Vitoria Rodrigues",
          badgeClass: "success",
          status: "Deal Won",
          statusValue: "$180K",
        },
        {
          id: 5,
          name: "Apple Inc.",
          date: "Sep 30, 2021",
          img: require("@/assets/images/users/avatar-6.jpg"),
          representativeName: "Vitoria Rodrigues",
          badgeClass: "info",
          status: "New Lead",
          statusValue: "$78.9K",
        },
      ],
    };
  },
  methods: {
    getItensAnterior() {
      if (this.page > 1) {
        this.page--;
        this.ConsultaProcedimentoUsuario();
      }
    },
    getItensProxima() {

      if (this.page < this.totalPages) {
        this.page++;
        this.ConsultaProcedimentoUsuario();
      }

    },
    async ConsultaProcedimentoUsuario() {

      try {
        this.procedimentos = false;
        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard/usuarioprocedimento/' + this.Usuario + '?page=' + this.page+'&search='+this.search+'&type=all');
        this.procedimentos = result.data.data;
        this.totalPages = result.data.last_page;

      } catch (e) {
        console.log(e);
      }
    },
    formatarData(data,formato = 'DD/MM/YYYY HH:mm:ss') {
      return moment(data).format(formato);
    }
  },
  mounted() {



  },
  watch: {
    changeTab(newVal) {
      if(newVal === 'Procedimento'){
        this.ConsultaProcedimentoUsuario();
      }
    },
    search() {
      this.ConsultaProcedimentoUsuario()
    }
  },
  computed: {
    isActive() {
      return this.activeTab === 'tab1'
    }
  }
};
</script>
