<template>
  <div style="text-align:center;">
  <content-loader
                    width={{width}}
                    height={{height}}
                    viewBox="0 0 300 60"
                    :speed="1"
                    primaryColor="#ffffff"
                    secondaryColor="#e3e3e3"
  >
    <rect x="13" y="7" rx="3" ry="3" width="88" height="6" />
    <rect x="12" y="26" rx="3" ry="3" width="52" height="6" />
  </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: "LoadingText",
  components: {
    ContentLoader,
  },
  props: ['width','height','speed']
}
</script>

<style scoped>
content-loader {
  display: block;
  margin: auto;
}
</style>